





































import { User, Global, Reports, Projects } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import GroupsListTable from '@/components/GroupsListTable.vue';
import API, { Types } from '@/modules/API';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import LoadingDialog from '@/components/dialogs/LoadingDialog.vue';
import Utils from '@/modules/Utils';
import moment from 'moment-timezone';

@Component({
    components: { 
        GroupsListTable,
        MessageDialog,
        LoadingDialog
    }
})

export default class ChangeGroupMode extends Vue {
    @User.State('project') project;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.Getter('commissioned_map') commissioned_map;
    @Projects.State('projects_metadata') projects_metadata;

    selectedGroups = [];
    mode = 1;
    isLoadingDialogOpen = false;
    isMessageDialogOpen = false;
    notCompleted = [];
    message = 'You must choose at least one item in table';

    mounted(){
        this.setPageTitle('Group Status');
    }

    get groupModes(){
        return [
            { text: this.$t('Connected'), value: 1},
            { text: this.$t('Fault In Care'), value: -9}, 
            { text: this.$t('Maintenance'), value: -1}
        ];
    }

    updateSelectedGroups(selected){
        this.selectedGroups = [...selected];
    }

    async handleChangeClicked(){
        if (!this.selectedGroups.length) {
            this.isMessageDialogOpen = true;
            return;
        }
        this.isLoadingDialogOpen = true;

        await Promise.all(this.selectedGroups.map((group) => this.updateGroupMode(group, this.mode)));

        this.updateSelectedGroups([]);
        this.isLoadingDialogOpen = false;
        
        this.notCompleted.length ? this.notifyStatus(false) : this.notifyStatus(true);
    }     

    async updateGroupMode(group, mode){
        let groupData = null;
        try {
            groupData = await API.get(Types.PROJECTS, `${this.project.id}/groups/${group.id}`, {}, {}, 'v4');
        }catch(e){}

        if (!groupData){
            this.notCompleted.push(group.id);
            return;
        }
        
        let isCompleted = true;

        const slices = Math.ceil(groupData.devices.length / 100);
        let startIndex = 0, endIndex = Math.min(groupData.devices.length, 100);
        for (let i = 0; i < slices; i++){
            const current = groupData.devices.slice(startIndex, endIndex);
            await Promise.all(current.map( async (device_id) => {
                try {
                    const response = await this.updateDeviceMode(device_id, mode);
                    if (response.ok) {
                        const device_on_store = this.commissioned_map.get(device_id);
                        if (device_on_store) {
                            this.updateDeviceModeOnStore(device_on_store);
                        }
                    }
                }catch (e){
                    isCompleted = false;
                }
            }));
            startIndex = endIndex;
            endIndex = Math.min(groupData.devices.length, endIndex + 100);
        }
        if (!isCompleted) this.notCompleted.push(group.id);
    }

    updateDeviceModeOnStore(device){
        const prev_status = Utils.getDeviceStatusByPieChart(device);

        device['sys___active'] = this.mode;
        let last_change = moment().utcOffset(0).valueOf(); // milliseconds
        last_change = Math.floor(last_change / 1000); // seconds
        device['sys___active__modified'] = last_change;

        const new_status = Utils.getDeviceStatusByPieChart(device);
        const activity_chart = this.projects_metadata.get(this.project.id).activity;
        const prev_status_count = activity_chart.find((status) => status.place === prev_status);
        prev_status_count.value -= 1;
        const new_status_count = activity_chart.find((status) => status.place === new_status);
        new_status_count.value += 1;
    }

    updateDeviceMode(device_id, mode){
        return API.post('', `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${device_id}/streams/sys___active/evt/`, { content: mode }, {}, 'v4');
    }

    notifyStatus(isCompleted){
        let notifyData;
        if (isCompleted){
            notifyData = {
                text: `All groups updated successfully!`,
                type: 'success',
                title: 'Success!'
            };
        }else{
            let groupsNotCompleted = '';
            this.notCompleted.forEach((id) => groupsNotCompleted = groupsNotCompleted + id + ', ');
            notifyData = {
                text: `Some mode devices in groups: ${groupsNotCompleted} could not update. Please try again later.`,
                type: 'error',
                title: 'Error!'
            };
        }
        
        this.$notify(notifyData);
    }
}

